import React, { Component } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import './email-subscription.css'



const CustomForm = ({ status, message, onValidated }) => {

  let email = ''
  const submit = () => {
    email &&
    // email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      // NAME: name.value
    })
  }


  function keyPress(e) {
    if(e.keyCode === 13){
      submit()
    }
  }

  function messageKor(message) {
    if(message.includes('recent signup requests') || message.includes('already subscribed')) {
      return '이미 등록되어 있습니다.'
    } else if(message.includes('enter a value')) {
      return '이메일을 입력해 주세요.'
    }else {
      return '어랏? 이메일 주소가 이상한거 같은데요?'
    }
  }

  return (
    <div className='email-input-container'>
      <div className={status === 'success' ? 'email-input-container-top collapsed' : 'email-input-container-top'}>
        <div className='email-subscription-title'>
          디자인 스펙트럼의 뉴스레터를 구독하세요
        </div>
        <div className='email-input-box'>
          <input
            className='email-input-textfield'
            // value={email}
            ref={node => (email = node)}
            type="email"
            placeholder="이메일 주소"
            // onChange={event => handleInputField(event)}
            onKeyUp={keyPress}
          />
          <button
            className='btn-email-subscription-submit'
            onClick={submit}
          >
            <div className='btn-confirm-arrow'></div>
          </button>
        </div>
      </div>

      {status === "sending" && (
        <div
          className='subscription-message subscription-message-sending'
        >
          보내는 중..
        </div>
      )}

      {status === "error" && (
        <div
          className='subscription-message subscription-message-error'
          // dangerouslySetInnerHTML={{ __html: messageKor(message) }}
        >
          {messageKor(message)}
          {/* {email.value = ''} */}
        </div>
      )}

      {status === "success" && (
        <div
          className='subscription-message subscription-message-success'
          // dangerouslySetInnerHTML={{ __html: message }}
        >
          {/* {console.log(email.value)} */}
          {/* {clearInputField} */}
          구독해 주셔서 감사합니다!
        </div>
      )}

    </div>
  );

}


class EmailSubscription extends Component {

  checkStatus(status) {

    if(status === 'error') {
      // console.log("TRUE")
      return true
    } else {
      return false
    }
    // console.log("FALSE")

  }

  render() {
    const mailChimpUrl = "https://designspectrum.us19.list-manage.com/subscribe/post?u=d2b5d868160b5de27acb4ea44&amp;id=d4f33e403c"
    let email

    return (
      <div className='email-subscription-container'>
        <MailchimpSubscribe
          url={mailChimpUrl}
          render={({ subscribe, status, message }) => (
            <div>
              {this.checkStatus(status)}
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
                // email={this.email}
                // emailInputField={this.state.emailInputField}
                // handleInputField={this.handleInputField}
                // clear={this.checkStatus(status)}
                // clearInputField={this.clearInputField}
              >
                {/* {console.log(status)} */}
                {/* {this.checkStatus(status)} */}
            </CustomForm>
          </div>
          )}
        />
      </div>
    );
  }
}

export default EmailSubscription
