import React from 'react'
import { Link } from 'gatsby'
import './footer.css'


const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-content footer-left'>
        <div className='copyright'><span>&copy;</span> 디자인 스펙트럼</div>
        <div>
          <Link to="/about">
            소개
          </Link>
        </div>
      </div>
      <div></div>
      <div className='footer-content footer-right'>
        <div className='menu-others-facebook'>
          <a href="https://www.facebook.com/sharedesignspectrum/" target="_blank" rel="noopener noreferrer">
            페이스북
          </a>
        </div>
        <div className='menu-others-youtube'>
          <a href="https://www.youtube.com/channel/UCu2_SsmtFAcDIuAA9H-lshA" target="_blank" rel="noopener noreferrer">
            유튜브
          </a>
        </div>
        <div className='menu-others-instagram'>
          <a href="https://www.instagram.com/designspectrum_official" target="_blank" rel="noopener noreferrer">
            인스타그램
          </a>
        </div>
        <div className='menu-others-podty'>
          <a href="https://www.podty.me/cast/174282" target="_blank" rel="noopener noreferrer">
            팟티
          </a>
        </div>
        <div className='menu-others-email'>
          <a href="mailto:contact@designspectrum.org">
            이메일
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer
