import React, { Component } from 'react'
import BlogPostItem from './blog-post-item'
import './blog-post-list.css'



let LOADING_ITEMS
if (typeof window !== `undefined`) {
  window.postsToShow = 12
}

class BlogPostList extends Component {

  constructor(props) {
    super(props);

    LOADING_ITEMS = 12
    let postsToShow = LOADING_ITEMS
    if (typeof window !== `undefined`) {
      postsToShow = window.postsToShow
    }

    this.state = {
      showingMore: postsToShow > LOADING_ITEMS,
      postsToShow,
    }
  }

  loadMore = () => {
    this.setState({
      postsToShow: this.state.postsToShow + LOADING_ITEMS,
      showingMore: true,
    })
  }

  update() {
    const distanceToBottom = document.documentElement.offsetHeight - (window.scrollY + window.innerHeight)
    // console.log(window.scrollY + window.innerHeight)
    if (this.state.showingMore && distanceToBottom < 120) {
      // this.loadMore()
      this.setState({
        postsToShow: this.state.postsToShow + LOADING_ITEMS,
      })
    }
    this.ticking = false
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(() => this.update())
    }
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
    // window.postsToShow = this.state.postsToShow
  }

  render() {
    let blogPostsFragment = []
    if(this.props.title === 'all') {
      blogPostsFragment = this.props.data.edges.map(edge => edge.node)
      // console.log(blogPostsFragment)
    } else {
      this.props.data.edges.map(edge => {
        // console.log(edge.node)
        if(edge.node.activityType === this.props.title) {
          blogPostsFragment.push(edge.node)
        }
      })
    }

    return (
      <div className='blogpost-list-container'>
        <div className={this.showingMore ? 'blogpost-list-container-no-bottom-padding' : 'blogpost-list-container-bottom-padding'}>
          <ul className='blogpost-list-container-inner'>
            {blogPostsFragment.slice(0, this.state.postsToShow).map(node => {
              return (
                <BlogPostItem
                  key={node.contentful_id}
                  activityType={node.activityType}
                  numbering={node.numbering}
                  title={node.title}
                  linkTo={node.linkTo}
                  slug={node.slug}
                  heroImage={node.heroImage}
                />
              )
            })}
          </ul>
          {blogPostsFragment.length > LOADING_ITEMS && !this.state.showingMore && (
            <div className="btn-load-more-container">
              <div className='btn-load-more'>
                <div
                  className='btn-load-more-text'
                  onClick={this.loadMore}
                >
                  LOAD MORE
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

}

export default BlogPostList
