import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import './blog-post-item.css'


const BlogPostItem = ({ activityType, numbering, title, linkTo, slug, heroImage }) => {
  let path = "/"
  const item = (
    <>
      <div className='activity-image-container'>
        {heroImage !== null ? <Img
          className='activity-image'
          fluid={{
            ...heroImage.fluid,
            aspectRatio: 1/1
          }}
          alt='Activity'å
        /> : <div className='placeholder-image'></div>}
      </div>
      <div className='activity-type-container'>
        <div className='activity-type-line'></div>
        <div className='activity-type'>
          {numbering === undefined ? activityType : numbering.length === 1 ? numbering : numbering[0] + '\xa0\xa0' + numbering[1] }
        </div>
      </div>
      <div className='activity-title'>
        {title}
      </div>
    </>
  )

  function ContextualLink() {
    if(activityType === "Spectrum Day") {
      path = "/spectrumday/"   
    }
    else if(activityType === "Spectrum Con") {
      path = "/spectrumcon/"   
    }
    else if(activityType === "Design Table") {
      path = "/designtable/"   
    }
    else if(activityType === "Design Box") {
      path = "/designbox/"   
    }
    else if(activityType === "Spectrum Picks") {
      path = "/spectrumpicks/"   
    }
    return (
      <Link to={path + slug}>
        {item}
      </Link>
    )
  }


  return (
    <li className='blog-post-item'>
      <div className='activity-item'>
        {ContextualLink()}
      </div>
    </li>
  );
}

export default BlogPostItem
